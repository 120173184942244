<template>
  <div>
    <SignContractBanner v-if="isInvestor"></SignContractBanner>
    <v-container
      id="pipeline"
      fluid
      tag="section"
      :class="gs.isMobile() ? 'px-0' : ''"
    >
      <v-row
        justify="center"
        align="center"
        class="pa-4 thesis-div-card"
        v-if="loading"
      >
        <v-progress-circular
          indeterminate
          size="35"
          color="primary"
        ></v-progress-circular>
      </v-row>
      <div v-else>
        <!-- market fake tabs -->
        <div>
          <v-tabs
            v-model="pipelineTab"
            @change="handleGoMarket"
            class="pipeline-header-tabs-container"
            color="primary"
          >
            <v-tab
              ><div class="tab tab-pipeline">
                <font-awesome-icon
                  icon="fa-solid fa-user-circle"
                  class="icon icon-pipeline"
                />
                <span class="label label-pipeline">{{ $t("pipeline") }}</span>
              </div></v-tab
            >
            <v-tab v-if="accessMarket">
              <div class="tab">
                <font-awesome-icon
                  icon="fa-solid fa-sliders"
                  class="icon icon-market"
                />
                <span class="label">{{ $t("market_app_label") }}</span>
              </div>
            </v-tab>
          </v-tabs>
        </div>

        <div
          class="thesis-div-card"
          v-if="theses && theses.length > 0 && envThesesCard === 'FALSE'"
        >
          <h4 class="pipeline-header">
            <b data-test="Alpha:Pipeline:TxtThesis">{{ $tc("thesis", 2) }}</b>
          </h4>
          <div class="pipeline-row">
            <table>
              <tr>
                <td
                  v-for="thesis in theses"
                  :key="thesis.id"
                  :class="[gs.isMobile() && 'pipeline-row-startMobile']"
                >
                  <ThesisCard :Thesis="thesis" />
                </td>
              </tr>
            </table>
          </div>
        </div>

        <div style="width: 100%">
          <div class="empty-pipeline" v-if="emptyPipeline">
            <p class="text">
              {{ $t("empty_pipeline_text") }}
            </p>
            <span
              class="link"
              @click="() => $router.push('/dashboard/investor')"
              >{{ $t("dashboard_investidor") }}</span
            >
          </div>
          <div>
            <CoManagerOpportunities
              v-if="comanagementCompanies.length > 0"
              :Title="$t('ongoing_opts')"
              :Category="comanagementCompanies"
              :Expanded="true"
            ></CoManagerOpportunities>
            <ExclusiveOpportunities
              v-if="exclusiveOpportunities.length > 0"
              :Title="$t('exclusive_opportunities')"
              :Category="exclusiveOpportunities"
              :Expanded="true"
              data-test="Pipeline:ExclusiveCompanies"
            ></ExclusiveOpportunities>
            <HighlightedCompanies
              v-if="highlightedCompanies.length > 0"
              :Title="$t('selected_for_you')"
              :Category="highlightedCompanies"
              :Expanded="exclusiveOpportunities.length < 1"
              data-test="Pipeline:HighlightedCompanies"
            ></HighlightedCompanies>
            <OngoingOpportunities
              v-if="ongoingOpportunities.length > 0"
              :Title="$t('ongoing_opts')"
              :Category="ongoingOpportunities"
              :Expanded="
                exclusiveOpportunities.length < 1 &&
                highlightedCompanies.length < 1
              "
              data-test="Pipeline:OngoingCompanies"
            ></OngoingOpportunities>
            <PortfolioCompanies
              v-if="portfolioCompanies.length > 0"
              :Title="$t('portfolio_companies')"
              :Category="portfolioCompanies"
              :Expanded="false"
              data-test="Pipeline:PortfolioCompanies"
            ></PortfolioCompanies>
          </div>
        </div>
      </div>
    </v-container>
    <v-dialog v-model="dialog" v-if="dialog">
      <v-container fluid tag="section">
        <v-card class="dxa_modal" style="padding-bottom: 15px">
          <div class="embed-container">
            <iframe
              :src="`https://player.vimeo.com/video/${selectedVideo}?portrait=0&byline=0&title=0`"
              frameborder="0"
              allowfullscreen
            ></iframe>
          </div>
        </v-card>
      </v-container>
    </v-dialog>

    <v-dialog style="display: flex" class="ma-0" v-model="signContractDialog">
      <v-card
        class="pa-5"
        style="
          display: flex;
          flex-direction: column;
          justify-items: center;
          max-width: 500px;
          text-align: center;
        "
      >
        <span style="display: flex; text-align: center">{{
          $t("bloqued_user")
        }}</span>
        <v-row class="ma-0">
          <v-col style="display: flex; justify-content: end">
            <v-btn
              style="min-width: 120px"
              class="secondary ma-0"
              @click="$router.push('/auth')"
              ><span>{{ $t("log_out") }}</span></v-btn
            >
          </v-col>
          <v-col>
            <v-btn
              style="min-width: 120px; display: flex; text-align: center"
              class="primary ma-0"
              ><span>{{ $t("continue") }}</span></v-btn
            >
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
  </div>
</template>

<style lang="scss">
.thesis-div-card {
  margin-bottom: 12px;
  background: rgba(218, 218, 218, 0.5);
  box-shadow: 0px 7px 20px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  padding: 18px 30px 18px 44px;
}
.pipeline-card {
  border: 1px solid #9c9c9c;
  box-sizing: border-box;
  box-shadow: 0px 7px 20px rgba(0, 0, 0, 0.1) !important;
  border-radius: 10px;
}
.pipeline-card-header {
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: 25px;
  letter-spacing: 0.15000000596046448px;
  margin-bottom: 6px !important;
  text-align: left;
  font-family: "Source Sans Pro";
}
.pipeline-card-text {
  color: #9c9c9c;
  font-size: 16px;
  font-family: "Source Sans Pro";
  font-style: normal;
  font-weight: 400;
  line-height: 15px;
  letter-spacing: 0em;
  text-align: left;
  max-height: 32px;
  overflow: scroll;
  margin-bottom: 0px !important;
}
.pipeline-card-text::-webkit-scrollbar {
  display: none;
}

.pipeline-header {
  @media only screen and (max-width: 600px) {
    font-size: 20px !important;
    line-height: 35px !important;
    letter-spacing: 0.15px !important;
  }
  color: var(--dark);
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 45px;
  letter-spacing: 0em;
  text-align: left;
  font-family: "Source Sans Pro Bold";
  text-align: left;
}
.pipeline-row::-webkit-scrollbar {
  background: transparent;
  border-radius: 10px;
  height: 12px;
}
.pipeline-row::-webkit-scrollbar-thumb {
  background: #c4c4c4;
  border-radius: 10px;
}
.pipeline-row {
  width: auto !important;
  display: block !important;
  overflow-x: scroll !important;
  width: 100% !important;
  padding-bottom: 20px !important;
}

.pipeline-row table {
  width: max-content !important;
}

.pipeline-row-startMobile {
  max-width: 300px;
}

.pipeline-row tr td {
  @media only screen and (max-width: 600px) {
    width: 270px !important;
  }
  width: 390px !important;
}
.PipelineDivider {
  margin: 20px;
}

.PipelineCard {
  margin-right: auto;
}

.company-status {
  font-family: "Source Sans Pro";
  color: gray;
  font-size: 13px;
  margin-top: auto;
  margin-bottom: auto;
}

@import "@/sass/briefing.scss";
.Pipeline-title {
  word-break: break-word;
  white-space: normal;
}
.div_card {
  position: absolute;
  bottom: 0;
  // width: 75%;
}
.full_width_button {
  margin-left: 3%;
  margin-right: auto;
  width: 90%;
}
.description {
  margin-bottom: 40px;
  margin-left: 16px;
  margin-right: 16px;
  margin-top: 16px;
}

.v-card.v-sheet.theme--light {
  -webkit-box-shadow: 1px 1px 3px 0px rgba(84, 77, 84, 1);
  -moz-box-shadow: 1px 1px 3px 0px rgba(84, 77, 84, 1);
  box-shadow: 1px 1px 3px 0px rgba(84, 77, 84, 1);
}

.pipeline-header-tabs-container {
  margin-bottom: 52px;
  padding-left: 32px;
}

.pipeline-header-tabs-container .tab {
  display: flex;
  align-items: center;
  gap: 6px;
}

.pipeline-header-tabs-container div.tab .icon {
  width: 16px;
  height: 16px;
}

.pipeline-header-tabs-container div.tab .label {
  font-size: 16px;
  font-weight: 500;
  display: flex;
}

.pipeline-header-tabs-container div.tab .icon-market {
  transform: rotate(270deg);
}

.empty-pipeline {
  display: grid;
  place-items: center;
  padding-left: 32px;
  padding-right: 22px;
}

.empty-pipeline .text {
  font-size: 20px;
  font-weight: 500;
  text-align: center;
  line-height: 1.5;
  margin-bottom: 12px;
}

.empty-pipeline .link {
  cursor: pointer;
  display: block;
  text-decoration: underline;
  font-size: 20px;
  color: var(--primary);
  font-weight: bold;
  text-align: center;
  padding: 4px;
  border-radius: 8px;
  height: 44px;
  transition: 0.3s;
  width: 220px;
}

.empty-pipeline .link:hover {
  background-color: var(--primary);
  color: var(--white);
}
</style>

<script>
import ApiService from "@/services/ApiService";
import GeneralServices from "@/services/GeneralServices";
// import CompleteProfileBanner from "@/views/pages/CompleteProfileBanner";
import ExclusiveOpportunities from "./cards/CompanyCard";
import OngoingOpportunities from "./cards/CompanyCard";
import SignContractBanner from "@/views/pages/SignContractBanner";
import HighlightedCompanies from "./cards/CompanyCard";
import PortfolioCompanies from "./cards/CompanyCard";
import ThesisCard from "./cards/ThesisCard";
import CoManagerOpportunities from "./cards/CompanyCard";
import { InvestorTypeEnum } from "@/shared/enums/InvestorTypeEnum";
import moment from "moment";
import { UserTypeEnum } from "@/shared/enums/UserType";
import { redirectMarketApp } from "@/shared/helpers/redirectMarketApp";

export default {
  name: "DashboardPipeline",

  data: () => ({
    apiService: new ApiService(),
    gs: new GeneralServices(),
    env: process.env.VUE_APP_BASE_URL,
    envThesesCard: process.env.VUE_APP_HIDDEN_PIPELINE_THESES_CARD,
    loading: false,
    open_only: false,
    filter_selected: null,
    selectedVideo: null,
    isInvestor: false,
    exclusiveOpportunities: [],
    ongoingOpportunities: [],
    highlightedCompanies: [],
    portfolioCompanies: [],
    comanagementCompanies: [],
    theses: [],
    dialog: false,
    companies: [],
    progress_bar_mock: 50,
    onboardingDialog: false,
    loggedUser: null,
    lastTransaction: null,
    signContractDialog: false,
    UserTypeEnum,
    redirectMarketApp,
    pipelineTab: 0,
    pipelineTabsEnum: {
      PIPELINE: 0,
      MARKET: 1,
    },
    accessMarket: false,
    emptyPipeline: false,
    companyPipelineIsNull: false,
    pipelineExclusiveOpportunitiesIsNull: false,
    pipelineHighlightedCompaniesIsNull: false,
    pipelineComanagementCompaniesIsNull: false,
  }),
  components: {
    // CompleteProfileBanner,
    OngoingOpportunities,
    SignContractBanner,
    HighlightedCompanies,
    PortfolioCompanies,
    ExclusiveOpportunities,
    CoManagerOpportunities,
    ThesisCard,
  },
  async created() {
    const userLocal = JSON.parse(localStorage.getItem("user"));
    this.loggedUser = userLocal;

    if (this.envThesesCard === "FALSE") {
      await this.get_theses_list();
    }

    this.loading = true;
    this.emptyPipeline = false;
    if (userLocal.type === this.UserTypeEnum.Investor) {
      this.isInvestor = true;
      await this.lastTransition();
      await this.getInvestorAccessMarketByPartner();
    } else {
      this.accessMarket = true;
    }

    await this.getPipeline();
    await this.getPipelineHighlightedCompanies();
    await this.getPipelineExclusiveOpportunities();
    await this.getPipelineComanagementCompanies();
    this.loading = false;
  },
  computed: {
    filters: function () {
      return [{ Value: this.$t("alfa_order"), Index: 0 }];
    },
  },
  watch: {
    loading() {
      if (!this.loading) {
        const emptyPipeline =
          this.companyPipelineIsNull &&
          this.pipelineExclusiveOpportunitiesIsNull &&
          this.pipelineHighlightedCompaniesIsNull &&
          this.pipelineComanagementCompaniesIsNull;

        this.emptyPipeline = emptyPipeline;
      }
    },
  },
  methods: {
    handleGoMarket(tab) {
      if (tab === this.pipelineTabsEnum.MARKET) {
        // got home market app
        // false in second argument to open de market app in the same window.
        this.redirectMarketApp("/", false);
      }
    },
    async getInvestorAccessMarketByPartner() {
      await this.apiService
        .getRequest(`partnerb2b/${this.loggedUser.partnerB2b.id}/market-access`)
        .then((resp) => {
          this.accessMarket = resp.content.accessMarket;
        })
        .catch((err) => {
          this.$toast.error(this.$t("an_error_ocurred"));
        });
    },
    async get_theses_list() {
      await this.apiService
        .getRequest("thesis/list")
        .then((resp) => {
          this.theses = resp.content;
        })
        .catch(() => {
          this.$toast.error(this.$t("an_error_ocurred"));
        });
    },

    // pro investidor não engajado, mantém o padrão de assinar o contrato
    // o investidor engajado pode 'pular'o processo de assinatura
    lastTransition() {
      const signContractLater = localStorage.getItem("signContractLater");
      this.apiService
        .getRequest(`investor/must-investor-renew-contract`)
        .then((response) => {
          if (response.content.shouldSignContract) {
            this.loggedUser.aYearWithoutInvestments = true;
            this.loggedUser.investorType = response.content.investorType;
            localStorage.setItem("user", JSON.stringify(this.loggedUser));
            if (
              response.content.investorType === InvestorTypeEnum.NOT_ENGAGED
            ) {
              this.$store.commit("SET_RENEW_INVESTOR_SIGNATURE", true);
              this.$router.push("/renewContracts");
            } else if (
              response.content.investorType === InvestorTypeEnum.ENGAGED &&
              !signContractLater
            ) {
              this.$store.commit("SET_RENEW_INVESTOR_SIGNATURE", true);
              this.$router.push("/renewContracts");
            }
          }
        })
        .catch(() => {
          this.$toast.error(this.$t("an_error_ocurred"));
        });
    },
    async verifyUserInvestment() {
      await this.apiService.getRequest().then().catch();
    },
    async getPipeline() {
      this.companyPipelineIsNull = false;

      await this.apiService
        .getRequest("company/pipeline")
        .then((resp) => {
          if (!resp || !resp.message) {
            this.companyPipelineIsNull = true;
            return;
          }

          let model = JSON.parse(resp.message);

          if (model) {
            const availableOngoingOpportunities =
              model.OngoingOpportunities &&
              model.OngoingOpportunities.length > 0;
            const availablePortfolioCompanies =
              model.PortfolioCompanies && model.PortfolioCompanies.length > 0;

            if (availableOngoingOpportunities) {
              this.ongoingOpportunities = this.initializeCompanies(
                model.OngoingOpportunities
              );
            }

            if (availablePortfolioCompanies) {
              this.portfolioCompanies = this.initializeCompanies(
                model.PortfolioCompanies
              );
            }

            if (
              !availableOngoingOpportunities &&
              !availablePortfolioCompanies
            ) {
              this.companyPipelineIsNull = true;
            }
          } else {
            this.companyPipelineIsNull = true;
          }
        })
        .catch(() => {
          this.$toast.error(this.$t("an_error_ocurred"));
        });
    },
    async getPipelineExclusiveOpportunities() {
      this.pipelineExclusiveOpportunitiesIsNull = false;

      await this.apiService
        .getRequest("company/pipeline-exclusive-opportunities")
        .then((resp) => {
          if (!resp || !resp.message) {
            this.pipelineExclusiveOpportunitiesIsNull = true;
            return;
          }

          let model = JSON.parse(resp.message);

          if (
            model &&
            model.ExclusiveOpportunities &&
            model.ExclusiveOpportunities.length > 0
          ) {
            this.exclusiveOpportunities = this.initializeCompanies(
              model.ExclusiveOpportunities
            );
          } else {
            this.pipelineExclusiveOpportunitiesIsNull = true;
          }
        })
        .catch(() => {
          this.$toast.error(this.$t("an_error_ocurred"));
        });
    },
    async getPipelineHighlightedCompanies() {
      this.pipelineHighlightedCompaniesIsNull = false;

      await this.apiService
        .getRequest("company/pipeline-highlighted-companies")
        .then((resp) => {
          if (!resp || !resp.message) {
            this.pipelineHighlightedCompaniesIsNull = true;
            return;
          }

          let model = JSON.parse(resp.message);

          if (
            model &&
            model.HighlightedCompanies &&
            model.HighlightedCompanies.length > 0
          ) {
            this.highlightedCompanies = this.initializeCompanies(
              model.HighlightedCompanies
            );
          } else {
            this.pipelineHighlightedCompaniesIsNull = true;
          }
        })
        .catch(() => {
          this.$toast.error(this.$t("an_error_ocurred"));
        });
    },
    async getPipelineComanagementCompanies() {
      this.pipelineComanagementCompaniesIsNull = false;

      await this.apiService
        .getRequest("company/pipeline-comanagement-companies")
        .then((resp) => {
          if (!resp || !resp.message) {
            this.pipelineComanagementCompaniesIsNull = true;
            return;
          }

          let model = JSON.parse(resp.message);

          if (
            model &&
            model.CoManagedCompanies &&
            model.CoManagedCompanies.length > 0
          ) {
            this.comanagementCompanies = this.initializeCompanies(
              model.CoManagedCompanies
            );
          } else {
            this.pipelineComanagementCompaniesIsNull = true;
          }
        })
        .catch(() => {
          this.$toast.error(this.$t("an_error_ocurred"));
        });
    },
    initializeCompanies(companies) {
      return companies.map((x) => {
        if (x.Description) {
          if (this.gs.isJson(x.Description)) {
            x.Description = JSON.parse(x.Description);
          }
        }
        if (x.Briefing.IntroVideo) {
          if (this.gs.isJson(x.Briefing.IntroVideo)) {
            x.Briefing.IntroVideo = JSON.parse(x.Briefing.IntroVideo);
          }
        }
        return x;
      });
    },
    select_changed: function () {
      if (this.filter_selected.Index == 0) {
        this.sort_companies(
          this.companies.sort(function (a, b) {
            if (a.Name < b.Name) {
              return -1;
            }
            if (a.Name > b.Name) {
              return 1;
            }
            return 0;
          })
        );
      } else {
        this.sort_companies(this.companies);
      }
    },
    sort_companies: function (companies) {
      var i;
      this.sorted_companies = [];
      var list = [];
      for (i = 0; i < companies.length; i++) {
        list.push(companies[i]);
        if (list.length == 3) {
          this.sorted_companies.push(list);
          list = [];
        }
      }
      if (list.length > 0) {
        this.sorted_companies.push(list);
      }
    },
  },
};
</script>

<style scoped></style>
